<template>
    <base-layout>
        <payments-nav :user="user" />
        <guest-progress :show="showProgress" :value="progressPercent" />
        <dyna-table v-if="payments.length > 0"
            tableClasses="table is-striped is-fullwidth"
            :headers="headers"
            :data="payments"
            rowKey="PLANID"
            :supportMobile="true"
        />
      <p v-else-if="!showProgress" class="notification is-warning">
        No payment history records found for Plan ID '{{ $route.params.planId }}'
      </p>
    </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import PaymentsNav from "./PaymentsNav.vue";
import GuestProgress from "../commons/GuestProgress.vue";
import DynaTable from "@/components/DynaTable.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
    components: {
        BaseLayout,
        DynaTable,
        PaymentsNav,
        GuestProgress
    },
    props: ["user"],
    data() {
        return {
            showProgress: false,
            progressPercent: 0
        };
    },
    methods: {
      ...mapMutations("g", ["SET_DEMPORTALUSERNAME","SET_PAYMENTS"]),
      ...mapActions("g", ["fetchPayments"])
    },
    computed: {
        ...mapState("g", ["payments","demPortalUsername"]),
        headers() {
            if( this.payments && this.payments.length > 0) {
                return Object.values(this.payments[0])
            }
            return [];
        }
    },
    beforeRouteEnter(to,from,next) {
        next( async vm => {
          try {
            vm.showProgress = true;
            vm.progressPercent = 10;

            vm.SET_PAYMENTS([]);

            const planId = vm.$route.params.planId;

            if( !vm.demPortalUsername ) {
              vm.SET_DEMPORTALUSERNAME(vm.user);
            }

            await vm.fetchPayments(planId);
          } finally {
            vm.showProgress = false;
            vm.progressPercent = 0;
          }
        });
    }
};
</script>